/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ContentPageTemplate from "../../templates/content-page";
const MDXLayout = ContentPageTemplate;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Tiefenpsychologisch fundierte Psychotherapie"), "\n", React.createElement(_components.p, null, "Die ", React.createElement(_components.strong, null, "tiefenpsychologisch fundierte Psychotherapie"), " arbeitet auf den theoretischen Grundlagen der Psychoanalyse, nimmt jedoch gezielter bestimmte Themen, mit denen der Patient sich plagt, in das Zentrum der Behandlung. Anhand aktueller Situationen, die klärend besprochen werden, wird an den zentralen inneren Spannungen gearbeitet. Dieser sogenannte Aktualkonflikt erfährt eine biographische Einordnung und im Durcharbeiten des Themas ergeben sich neue Einsichten. Die eigene Perspektive auf das Problem ändert sich und neue Lösungsmöglichkeiten im Umgang mit der jeweils schwierigen Situation können sich eröffnen. Oft geht es darum, wieder in einen guten Kontakt mit sich selbst und der nahen sozialen Umgebung zu kommen, krankmachende Mechanismen und Reaktionen, Einstellungen zu identifizieren und zu überprüfen."), "\n", React.createElement(_components.p, null, "Domäne der tiefenpsychologischen Psychotherapie sind hauptsächlich Depressionen, Ängste, Traumafolgestörungen und in gewissem Umfang auch Persönlichkeitsstörungen."), "\n", React.createElement(_components.p, null, "In der Regel erfolgt eine Behandlungssitzung pro Woche, wobei die Behandlungsfrequenz individuell festgelegt wird. Die tiefenpsychologische Psychotherapie erfolgt als Kurzzeittherapie bis 25 Sitzungen oder als Langzeittherapie bis 50, in besonderen Situationen bis 80, maximal 100 Sitzungen."));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
